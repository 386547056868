<template>
  <div>
    <Header :nav-items="navItems" @sendurl="changeSection"></Header>
    <div class="courseMain">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'courseMain',
  components: { Header },
  data () {
    return {
      navItems: [
        {
          index: '1',
          tab: '课程信息',
          src: 'CourseMessage',
          default: true
        },
        {
          index: '2',
          tab: '学习',
          src: 'CourseList'
        },
        {
          index: '3',
          tab: '考试',
          src: 'AllMyExams'
        },
        {
          index: '4',
          tab: '任务',
          src: 'MutualEvaluation'
        },
        {
          index: '5',
          tab: '讨论',
          src: 'AllComment'
        },
        {
          index: '6',
          tab: '资料',
          src: 'StudyFiles'
        },
        {
          index: '7',
          tab: '统计',
          src: 'Statistics'
        }
      ]
    }
  },
  beforeCreate () {
    localStorage.setItem('termId', this.$route.params.termId)
  },
  created () {
    // this.termId = this.$route.params.termId
    console.log(this.this.$route.params.termId)
  },
  // watch:{
  //   $route(){
  //     const metaData={
  //       termId: this.$route.termId
  //     }
  //   }
  // },
  methods: {
    changeSection (value) {
      this.$router.push({
        name: value,
        params: { termId: localStorage.getItem('termId') }
      })
    }
  }
}
</script>

<style scoped>
.courseMain {
  margin-top: 0;
}
</style>
